import axios from 'axios'
import Toastr from './Toaster';

const endpoints = require('./endpoints')

const DEFAULT_ERROR_NOTIFICATION = "Something went wrong!";
const MAX_RETRIES = 3; // Number of retries for BobbyAPI
const RETRY_DELAY = 1000; // Delay between retries in milliseconds

// Utility function to delay execution
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main API instance
const API = axios.create({
  baseURL: endpoints.APIURL,
  withCredentials: true,
})

// Bobby API instance
const BobbyAPI = axios.create({
  baseURL: 'https://bobby.featherhealth.ai:8080/',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer S*JQNWzYqYzkFD5*qXG-p@u_KYz%FRh2Kz%yPaC4m4pPndu-5d&2f%^VnyEwsZn+S@x8e9^E',
    'Access-Control-Allow-Origin': '*' 
  }
});

// Add response interceptor with retry mechanism for BobbyAPI only
BobbyAPI.interceptors.response.use(
  response => handleSuccessResponse(response),
  async (error) => {
    const { config, response } = error;
    const shouldRetry = response?.status >= 500 && !config._retry;

    if (shouldRetry && config._retryCount < MAX_RETRIES) {
      config._retry = true;
      config._retryCount = (config._retryCount || 0) + 1;
      await delay(RETRY_DELAY);
      return BobbyAPI(config);
    }

    return handleErrorResponse(error);
  }
);

API.interceptors.response.use(
  response => handleSuccessResponse(response),
  error => handleErrorResponse(error) 
);

const handleSuccessResponse = response => {
  if (response.status === 201) {
    if (response.data.msg) {
      Toastr.success('Created!');
    }
  }
  return response;
};

const handleErrorResponse = axiosErrorObject => {
  if (axiosErrorObject.response?.status === 401) {
    localStorage.removeItem('authenticated')
    setTimeout(() => (window.location.href = "/"), 2000);
  } else if (axiosErrorObject.response?.status === 500) {
    if (!axiosErrorObject.response?.config.url.includes('/record/get/')) {
      Toastr.error(
        axiosErrorObject.response?.data?.error || DEFAULT_ERROR_NOTIFICATION
      );
    }
  } else if (axiosErrorObject.response?.status === 400) {
    Toastr.error(
      axiosErrorObject.response?.data?.error || "UNVALID PARAMS"
    );
  } else if (axiosErrorObject.response?.status === 422) {
    Toastr.error(
      axiosErrorObject.response?.data?.error || "Unprocessable Entity"
    );
  } else if (axiosErrorObject.response?.status === 404) {
    Toastr.error(
      axiosErrorObject.response?.data?.error || "NOT FOUND"
    );
  } else if (axiosErrorObject.response?.status === 406) {
    Toastr.error(
      "Automatic check couldn't review this ECG"
    );
  }
  return Promise.reject(axiosErrorObject);
};

// Bobby API methods
API.bobby = {
  getEctopiBeats: (ecgId) => {
    return BobbyAPI.get('/predictions', {
      params: {
        ecg_id: ecgId,
        backend: 'prod',
        get_warm_message: false
      }
    });
  },
  
  getPQRST: (ecgId) => {
    return BobbyAPI.get('/pqrstmeasures', {
      params: {
        ecg_id: ecgId,
        backend: 'prod'
      }
    });
  },

  getWarmMessage: (ecgId, rhythmLabels, measurements, pacCount, pvcCount, heartRate) => {
    // Extract measurement values or use empty strings if not available
    const p = measurements?.find(m => m.interval_type === 'p')?.measurement || '';
    const pr = measurements?.find(m => m.interval_type === 'pr')?.measurement || '';
    const qrs = measurements?.find(m => m.interval_type === 'qrs')?.measurement || '';
    const qtc = measurements?.find(m => m.interval_type === 'qtc')?.measurement || '';
    
    // Format rhythm labels for the API
    const rhythmLabelsStr = rhythmLabels && rhythmLabels.length 
      ? rhythmLabels.map(rhythm => rhythm.name).join(',') 
      : '';
    
    return BobbyAPI.get('/get-warm-message', {
      params: {
        ecg_id: ecgId,
        backend: 'prod',
        pvc_count: pvcCount || 0,
        pac_count: pacCount || 0,
        rhythm_label: rhythmLabelsStr,
        hr: heartRate || 0,
        p: p,
        pr: pr,
        qtc: qtc,
        qrs: qrs
      }
    });
  }
};

export default API;
