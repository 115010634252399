import React, { useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router'

import { Box, Typography, Button,Tooltip, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import EKGViewer from '../../components/EKGViewer'
import Navigation from '../../components/Navigation'
import { ReviewTag } from './Review.styled';

const useStyles = makeStyles((theme) => ({
  revertedArrow: {
    transform: 'rotate(180deg)',
    marginRight: 5,
  },
  goBack: {
    display: 'flex',
    alignItems: 'center',
  },
  taskNumber: {
    fontSize: 16,
  },
  fullWidth: {
    flexGrow: 1,
  },
  backButton: {
    padding: 2,
  },
}))



function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function Review() {
  const history = useHistory()
  const { id } = useParams()
  const classes = useStyles()
  const query = useQuery()
  const mode = query.get('mode')

  const [forceAuditSubmit, setForceAuditSubmit] = useState(null)

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    // Copy the task ID to clipboard
    navigator.clipboard.writeText(id);
    // Set copied state to true
    setCopied(true);
    // Reset after 800ms
    setTimeout(() => {
      setCopied(false);
    }, 800);
  };


  const renderMenu = () => {
    const goBack = () => {
      history.push('/' + mode)
    }

    return (
      <Box style={{paddingTop:"10px",paddingBottom:"10px"}}>
        <Box onClick={goBack}>
          <Button className={classes.backButton}>
            <Typography variant="button" className={classes.goBack}>
              <ArrowRightAlt className={classes.revertedArrow} />
              Go back
            </Typography>
          </Button>
        </Box>
        <Box>
          <Typography variant="h6" className={classes.taskNumber}>
         { mode &&<ReviewTag  mode={mode}variant="outlined" size="small" color="warning" label={mode  && mode.charAt(0).toUpperCase() + mode.slice(1)+" Task"} />
 }    Task: {id}
           <Tooltip title={copied ? "Copied!" : "Copy Task ID"}>
        <IconButton 
          size="small" 
          onClick={handleCopy} 
          style={{ marginLeft: '4px' }}
        >
          {copied ? (
            <CheckCircleIcon style={{ color: 'green', fontSize: '16px' }} />
          ) : (
            <ContentCopyIcon style={{ fontSize: '16px' }} />
          )}
        </IconButton>
      </Tooltip>
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Navigation renderMenu={renderMenu} />
        <EKGViewer
          mode={mode}
          isAudit={mode === "audit"}
          isLabelling={mode === 'labeling'}
          isPractice={mode === 'practice'}
          setForceAuditSubmit={setForceAuditSubmit}
          forceAuditSubmit={forceAuditSubmit}
        />
    </>
  )
}

export default Review
