import React, { useEffect, useState, useRef, useMemo, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { loadTasksAsync } from '../../features/dashboard/tasksSlice'
import { loadLabellingTasksAsync } from '../../features/labelling/labellingTasksSlice'
import {
  selectReviewErrors,
  setReviewErrors,
} from '../../store/slices/reviewErrors'
import {
  selectReviewWarnings,
  setReviewWarnings,
} from '../../store/slices/reviewWarnings'
import { getEcg, selectEcg } from '../../store/slices/ecg'
import useAsyncReference from '../../hooks/useAsyncReference'

import { TASK_TYPE } from '../../common/constants'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { max, min } from 'd3-array';
// material UI stuff
import {
  Box,
  Grid,
  CssBaseline,
  Badge,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Typography,
  TextField,
  InputAdornment,
  Snackbar,
  FormHelperText,
  Button,
  Checkbox,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import HeightIcon from '@mui/icons-material/Height'
import LabelImportantIcon from '@mui/icons-material/LabelImportant'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import DownloadIcon from '@mui/icons-material/Download';
import TimelineIcon from '@mui/icons-material/Timeline';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

// graph stuff

import LineChart from '../LineChart'

// utilities
import API from '../../common/api'
import { useParams } from 'react-router-dom'
import { isEqual } from 'lodash'
import {
  submitStates,
  boxTypes,
  eventTypes,
  sanityErrors as sanityErrorsEnum,
} from '../../common/enums'
import {
  getKeysArray,
  capitalizeFirstLetter,
  elementInViewport,
  createDataset,
  getLabelColorByCategory,
} from '../../common/utils'
import * as endpoints from '../../common/endpoints'
import { sendEvent } from '../../common/requests'
import { INTERVAL_MAX_VALUE, INTERVAL_MIN_VALUE, SESSION_COOKIE, DEFAULT_SPLIT_FREQUENCY } from '../../common/constants'
import { setNotAuthenticated } from '../../features/login/loginSlice'
import UserHistory from '../UserHistory'
import UserCvdSurvey from '../UserCvdSurvey'
import LoaderDialog from '../LoaderDialog'
import AnnotationToolbar from '../AnnotationToolbar'
import Backdrop from '../Backdrop'
import ColCard from '../ColCard'
import InterpretationBar from '../InterpretationBar'
import CollapseHorizontal from '../CollapseHorizontal'
import AbsoluteTooltip from '../AbsoluteTooltip'
import { AuditCreate } from '../../features/audit/audit-create'
import { useCookies } from 'react-cookie'
import Empty from "../../assets/svgs/edit.svg"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { selectRhythms } from '../../store/slices/rhythms'
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { useTour } from '@reactour/tour'
import { FlipCameraAndroid, ZoomIn, ZoomOut, ZoomOutMap } from '@mui/icons-material'
import Toastr from '../../../src/common/Toaster';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { scaleLinear } from '@visx/scale'
import { Alert, isPdf } from './helper'

import { AuthContext } from '../../contexts/Auth.context';
import { TasksContext } from '../../contexts/Tasks.context';

// constants

let CHART = null

const useStyles = makeStyles((theme) => ({
  textArea: {
    color: 'black !important',
  },
  ecgWrapper: {
    marginTop: theme.spacing() * 2,
  },
  sectionsWrapper: {
    padding: "10px",
    background: "#f7f8fa",
  },
  intervalItem: {
    maxWidth: 100,
    margin: theme.spacing(),
  },
  chartWrapper: {
    // marginBottom: theme.spacing(),
  },
}))

function EKGViewer({ isAudit, isLabelling, mode, forceAuditSubmit, setForceAuditSubmit, isPractice }) {
  const { remainingActiveTasksNumber, activeTasks, assignActiveTask, getActiveTasks } = useContext(TasksContext)

  const { role } = useContext(AuthContext)

  const allRhythms = useSelector(selectRhythms)
  const [answer, setAnswer] = useState(null)
  const classes = useStyles()
  const history = useHistory()
  const [removeCookie] = useCookies()
  const reviewErrors = useSelector(selectReviewErrors)
  const reviewWarnings = useSelector(selectReviewWarnings)
  const ecg = useSelector(selectEcg)
  const [ecgViewType, setEcgViewType] = useState('graph');

  const [snackbar, setSnackbar] = useState(null)

  const [id, setId] = useState(null)
  const [data, setData] = useState([])
  const [dataAsyncRef, setDataAsyncRef] = useAsyncReference([])
  const [dataSplitted, setDataSplitted] = useState([])
  const [labels, setLabels] = React.useState({})
  const [PVCCount, setPVCCount] = useState(0)
  const [PACCount, setPACCount] = useState(0)
  const [QTC, setQTC] = useState(0)
  const [QT, setQT] = useState('')
  const [splitFrequency, setSplitFrequency] = useState(5116)

  const [activeRecordLabel, setActiveRecordLabel] = React.useState([])
  const [notes, setNotes] = useState('')
  const [notesSaving, setNotesSaving] = useState(false)

  const [intervalLabels, setIntervalLabels] = useState([])
  const [submitState, setSubmitState] = useState(submitStates.NONE)
  const [forceSubmit, setForceSubmit] = useState(null)

  const [analysisText, setAnalysisText] = useState('')

  const [activeTool, setActiveTool] = React.useState(0)
  const [currentTime, setCurrentTime] = React.useState(0.0)
  const [caliperState, setCaliperState] = useAsyncReference(null)
  const [activeLabel, setActiveLabel] = React.useState(0)
  const [annotations, setAnnotations] = useState([])

  const [minY, setMinY] = React.useState(-1)
  const [maxY, setMaxY] = React.useState(5)

  const [isLoaderDialogOpen, setIsLoaderDialogOpen] = React.useState(false)
  const [isInverted, setIsInverted] = useState(false)

  const [isBackdropOpen, setIsBackdropOpen] = useState(false)
  const [isChartMounted, setIsChartMounted] = useState(false)

  const [boxType, setBoxType] = useState(boxTypes.LARGE)
  const [sanityErrors, setSanityErrors] = useState([
    sanityErrorsEnum.NO_RHYTHM_SELECTED,
    sanityErrorsEnum.EMPTY_PQRST,
  ])

  const [chartTooltip, setChartTooltip] = useAsyncReference(null)

  const lastChartRef = useRef(null)
  const removeScrollEventRef = useRef(null)
  const chartMountedTimeoutRef = useRef(null)
  const warmMessageRef = useRef('')
  const currentEcgDataRef = useRef({})
  const rhythmsRef = useRef([])

  const [conversionValid, setConversionValid] = useState(true)

  const dispatch = useDispatch()
  const params = useParams()

  const validateSanityError = (isError, sanityErrorName) => {
    const isErrorInState = sanityErrors.includes(sanityErrorName)
    if (isError && !isErrorInState) {
      setSanityErrors((prevState) => [...prevState, sanityErrorName])
    }
    if (!isError && isErrorInState) {
      setSanityErrors((prevState) =>
        prevState.filter((error) => error !== sanityErrorName)
      )
    }
  }

  const validateLastChartScroll = () => {
    if (lastChartRef) {
      const isVisible = elementInViewport(lastChartRef.current)
      if (isVisible) {
        setSanityErrors((prevState) =>
          prevState.filter((error) => error !== sanityErrorsEnum.NO_FULL_SCROLL)
        )
      } else {
        setSanityErrors(prev => [...prev, sanityErrorsEnum.NO_FULL_SCROLL])
      }

      return isVisible
    }
  }

  useEffect(() => {
    if (ecg.medium) {
      setEcgViewType('media_file');
    }

    if (ecg?.medium) {
      setSanityErrors((prevState) =>
        prevState.filter((error) => error !== sanityErrorsEnum.NO_FULL_SCROLL)
        .filter((error) => error !== sanityErrorsEnum.EMPTY_PQRST)
      )
    }

    if (ecg?.ecg_id && (isPdf(ecg) || ecg.source === 'apple_watch' || ecg.source === 'withings' || ecg.source === 'samsung' || ecg.source === 'fitbit')) {
      getData(ecg.ecg_id)
    }

    if (role === 'guest') {
      setSanityErrors(prev => [...prev, sanityErrorsEnum.NO_ALLOW_FINISH_REVIEW])
    }
  }, [ecg])

  useEffect(() => {
    if (!isEqual(data, dataAsyncRef)) {
      setDataAsyncRef(data)
    }
    if (!isLabelling) {
      setSanityErrors(prev => [...prev, sanityErrorsEnum.NO_WARM_MESSAGE])
    }
  }, [data])

  function getData(recordID) {
    return new Promise((resolve, reject) => {
      API.get(endpoints.GETRECORD + (mode === 'tutorial' ? '43a4be7f-3d37-4701-a9df-c9948104dad2' : recordID))
        .then((data) => {
          // var splitFreq = ecg.sampling_frequency > 0 ? ecg.sampling_frequency * 10 : 5116
          // setSplitFrequency(splitFreq)
          setData([createDataset(data.data.data, false, ecg.sampling_frequency)])
          setEcgViewType('graph')
          resolve()
        })
        .catch((e) => {
          if (e.response?.status === 401) {
            API.get(endpoints.LOGOUT).finally((e) => {
              removeCookie(SESSION_COOKIE)
              dispatch(setNotAuthenticated())
            })
          }
          reject()
        })
    })
  }

  const getadditionalData = () => {

    const data = {}

    data.measurements = intervalLabels.map((im) => ({
      ...im,
      measurement: parseFloat(im.measurement) || 0,
    }))

    data.analysis = warmMessageRef.current
    data.PACCount = PACCount
    data.PVCCount = PVCCount
    data.avg_heart_rate = heartRate
    const started_at = localStorage.getItem(params.id)
    if (started_at) {
      data.started_at = started_at
      localStorage.removeItem(params.id)
    }

    data.annotations = Array.from(annotations)

    data.activerhythms = rhythmsRef.current.map((rhythm) => rhythm.id)
    data.conversion_valid = conversionValid

    return data
  }

  const handleSubmit = (validation = true) => {
    if (mode === "tutorial") {
      history.push("/live")
      return
    }
    if (!warmMessageRef.current?.length && !isLabelling) {
      return
    }
    const data = {}

    data.measurements = intervalLabels.map((im) => ({
      ...im,
      measurement: parseFloat(im.measurement) || 0,
    }))

    data.analysis = warmMessageRef.current
    data.avg_heart_rate = heartRate

    data.conversion_valid = conversionValid

    const started_at = localStorage.getItem(params.id)
    if (started_at) {
      data.started_at = started_at
      localStorage.removeItem(params.id)
    }

    data.annotations = Array.from(annotations)

    data.rhythms = rhythmsRef.current.map((rhythm) => rhythm.id)
    data.pac_count = PACCount
    data.pvc_count = PVCCount
    let url = endpoints.GETANNOTATIONSETTER(id)

    if (isLabelling) {
      url = endpoints.LABELLING_COMPLETE(id)
    }

    if (!validation) {
      url += '?skip_warning=yes'
    }
    if (isAudit) {
      setIsLoaderDialogOpen(true)
      setForceAuditSubmit(Date.now())
    } else {
      setIsBackdropOpen(true)

      API.post(url, data)
        .then(() => {
          handleChangeSubmitState(submitStates.IN_PROGRESS)
          setIsLoaderDialogOpen(true)
          return Promise.resolve()
        })
        .then(() => {
          handleChangeSubmitState(submitStates.DONE)
          // isLabelling ? dispatch(loadLabellingTasksAsync()) : dispatch(loadTasksAsync())
          setActiveRecordLabel([])
          setIsBackdropOpen(false)
          // Get new ECG after user submits the review - TO-DO: Check if doesn't breath the FILO principle
          // assignActiveTask()
        })
        .catch((e) => {
          if (e.response?.status === 401) {
            API.get(endpoints.LOGOUT).finally((e) => {
              removeCookie(SESSION_COOKIE)
              dispatch(setNotAuthenticated())
            })
          }
          if (e.response?.status === 422) {
            dispatch(setReviewErrors(e.response.data.errors.ecg))
            dispatch(setReviewWarnings(e.response.data.warnings.ecg))
            handleChangeSubmitState(submitStates.ERROR_ECG_ERROR)
            setIsBackdropOpen(false)
            setIsLoaderDialogOpen(true)
            sendEvent(id, eventTypes.sanity_triggered)
            return
          }
          console.error(e)
          // handleChangeSubmitState(submitStates.ERROR)
          setIsBackdropOpen(false)
        })
    }
  }

  const handleSubmitNoValidation = () => {
    handleSubmit(false)
  }

  useEffect(() => {
    if (typeof forceSubmit === 'number') {
      handleSubmit()
    }
  }, [forceSubmit])

  const defaultToolHandler = (event, elements, state) => { }

  const doLabelTool = (_, elements, state, options) => {
    if (elements.length < 1) return
  
    const { activeLabel, setLabels, labels, activeTool } = state
    const eventIndex = elements[0]._index + options.startedIndex
    
    console.log("Adding annotation - elements:", elements);
    console.log("Adding annotation - state:", {
      activeLabel,
      activeTool,
      eventIndex,
      options
    });
  
    const xValue = data[0][eventIndex].x
    const scaleID = elements[0]._xScale.id
  
    console.log("Adding annotation - calculated values:", {
      xValue,
      scaleID,
      existingLabel: labels[xValue]
    });
  
    if (!(xValue in labels)) {
      const label = toolMappingReverse[activeTool] === 'VLABEL' ? 1 : 2
      sendEvent(state.id, eventTypes.beat_added)
  
      console.log("Adding new annotation:", {
        eventIndex,
        label,
        toolType: toolMappingReverse[activeTool]
      });
  
      setAnnotations((prev) => {
        const prevState = prev || []
        const newState = Array.from(prevState)
        const sameAnnotationIndex = prevState.findIndex(
          (annotation) => annotation.idx === eventIndex
        )
  
        console.log("Updating annotations state:", {
          prevState,
          sameAnnotationIndex,
          willUpdate: sameAnnotationIndex > -1
        });
  
        if (sameAnnotationIndex > -1) {
          newState[sameAnnotationIndex] = {
            ...newState[sameAnnotationIndex],
            label,
          }
        } else {
          newState.push({
            idx: eventIndex,
            label,
          })
        }
        return newState
      })
  
      setLabels((prevState) => {
        const newLabel = makeAnnotation(
          xValue,
          scaleID,
          availableLabels[activeLabel].labelDisplay
        );
        console.log("Setting new label:", {
          prevState,
          newLabel,
          xValue
        });
        return {
          ...prevState,
          [xValue]: newLabel,
        };
      })
    }
  }
  

  const makeAnnotation = (x, scaleID, labelDisplay, options = {}) => {
    const { borderColor = 'blue', showLabel = true } = options;
    
    return {
      type: 'line',
      mode: 'vertical',
      scaleID: scaleID,
      value: x,
      borderColor: borderColor,
      borderWidth: 1,
      label: {
        position: 'top',
        enabled: showLabel,
        content: labelDisplay,
      },
    }
  }

  const makeRRInterval = (x, scaleID, labelDisplay) => {
    return {
      type: 'line',
      mode: 'vertical',
      scaleID: scaleID,
      value: x,
      borderColor: 'transparent',
      borderWidth: 0,
      borderDash: [0, 0], 
      label: {
        position: 'top',
        enabled: true,
        content: labelDisplay,
        color: '#000000', // Try hex code
        textColor: '#000000', // Or try textColor property
        fontColor: '#000000', // Or try fontColor property
        font: {
          weight: 'bold',
          color: 'grey',
          size: 6
        },
        backgroundColor: 'transparent',
        backgroundWidth: 0,
        backgroundHeight: 0,
        borderWidth: 0,
        borderRadius: 0,
        padding: 0
      },
    }
  }  

  const makeCaliperVertAnnotation = (y, xMin, xMax, xScaleID, yScaleID) => {
    return {
      type: 'box',
      xScaleID,
      yScaleID,
      yMin: y,
      yMax: y + 0.001,
      xMin,
      xMax,
      borderColor: 'rgba(158,158,158)',
      borderWidth: 1,
      backgroundColor: 'rgba(158,158,158, 0.5)',
      initial: y,
    }
  }

  const makeCaliperAnnotation = (x, yMin, yMax, xScaleID, yScaleID) => {
    return {
      type: 'box',
      xScaleID,
      yScaleID,
      xMin: x,
      xMax: x + 0.001,
      yMin,
      yMax,
      borderColor: 'rgba(158,158,158)',
      borderWidth: 1,
      backgroundColor: 'rgba(158,158,158, 0.5)',
      initial: x,
    }
  }

  const toolMapping = {
    CALIPER: 0,
    CALIPERVERT: 1,
    VLABEL: 2,
    ALABEL: 3,
    DELETE: 4,
  }

  const toolMappingReverse = {
    0: 'CALIPER',
    1: 'CALIPERVERT',
    2: 'VLABEL',
    3: 'ALABEL',
    4: 'DELETE',
  }

  const badgeFactory = (badgeContent) => {
    return (props) => (
      <Badge
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        overlap="circular"
        badgeContent={badgeContent}
        {...props}
      />
    );
  }

  const labelMapping = {
    VLABEL: 0,
    ALABEL: 1,
  }

  const availableLabels = [
    {
      name: 'pvc',
      labelDisplay: 'V',
      badgeDisplay: 'V',
    },
    {
      name: 'pac',
      labelDisplay: 'A',
      badgeDisplay: 'A',
    },
  ]

  const handleChange = (e, name) => {
    switch (name) {
      case 'boxType':
        setBoxType(e.target.value)
        break
      default:
        break
    }
  }

  const handleChangeSubmitState = (state) => {
    setSubmitState(state)
  }

  const computeQTC = (QT, RR) => {
    let QTC = QT / Math.sqrt(RR)
    const rest = QTC - parseInt(QTC)
    if (rest >= 0.5) {
      QTC = QTC + 1
    }
    QTC = parseInt(QTC)

    setIntervalLabels([
      {
        interval_type: 'qtc',
        measurement: isNaN(QTC) ? 0 : QTC,
      },
      ...intervalLabels.filter(
        ({ interval_type }) => interval_type !== 'qtc'
      ),
    ])
    setQTC(isNaN(QTC) ? 0 : QTC)
  }

  const computeQT = (QTc, RR) => {
    let QT = QTc * Math.sqrt(RR)
    const rest = QT - parseInt(QT)
    if (rest >= 0.5) {
      QT = QT + 1
    }
    QT = parseInt(QT)
    QT = isNaN(QT) ? 0 : QT
    setQT(QT)
  }

  const invertECG = () => {
    const newData = data[0].map(({ x, y }) => ({ x, y: -y }))
    if (caliperState.current) {
      let ncs = { ...caliperState.current }
      if (ncs.isHorizontalClipher) {

        const yMin = -ncs.yMax

        const yMax = -ncs.yMin
        ncs = {
          ...ncs,
          yMax,
          yMin,
        }
      } else {
        const yMax = ncs.yMax * -1
        const yMin = ncs.yMin * -1
        ncs = {
          ...ncs,
          yMax,
          yMin,
        }
      }
      setCaliperState(ncs)
    }
    setData([newData])
  }

  const doCaliperTool = (event, elements, state) => {
    if (elements.length < 1) return

    const { caliperState, setCaliperState } = state
    // No existing caliper state or caliper state finalized, so hover has no effect.
    if (
      event.type === 'mousemove' &&
      (!caliperState.current || caliperState.current.final)
    ) {
      return
    }

    const eventIndex = elements[0]._index
    const datasetIndex = elements[0]._datasetIndex
    const xValue =
      elements[0]._chart.data.datasets[datasetIndex].data[eventIndex].x
    const xScaleID = elements[0]._xScale.id
    const yScaleID = elements[0]._yScale.id
    const yMin = elements[0]._yScale.min
    const yMax = elements[0]._yScale.max
    if (event.type === 'mousemove') {
      if (caliperState.current.pageX && caliperState.current.pageY) {
        const tooltip = {
          y: caliperState.current.pageY,
          x: parseInt((caliperState.current.pageX + event.pageX) / 2),
        }
        setChartTooltip(tooltip)
      }



      setCaliperState((prevState) => {
        const anchor = prevState.initial
        const xMin = anchor < xValue ? anchor : xValue
        const xMax = anchor > xValue ? anchor : xValue
        return { ...prevState, xMin, xMax }
      })
    }
    if (event.type === 'click') {
      // Place first caliper anchor
      if (!caliperState.current) {
        setChartTooltip(null)
        setCaliperState({
          ...makeCaliperAnnotation(xValue, yMin, yMax, xScaleID, yScaleID),
          isHorizontalClipher: true,
          pageX: event.pageX,
          pageY: event.pageY,
          mode: 'horizontal',
          draggable: true,
          onDrag: function (e) {
            setCaliperState((prevState) => {
              const r = e.subject.config.xMin + ((e.x - prevState.pageX) / ecg.sampling_frequency)
              const { final, initial } = caliperState.current
              const diff = Math.abs(final - initial)

              return {
                ...prevState,
                xMin: r,
                xMax: r + diff,

              }
            })
          }
        })
      } else if (caliperState.current.initial && !caliperState.current.final) {
        if (caliperState.current.pageX && caliperState.current.pageY) {
          const tooltip = {
            y: caliperState.current.pageY,
            x: parseInt((caliperState.current.pageX + event.pageX) / 2),
          }
          setChartTooltip(tooltip)
        }
        setCaliperState((prevState) => {
          const anchor = prevState.initial
          const xMin = anchor < xValue ? anchor : xValue
          const xMax = anchor > xValue ? anchor : xValue
          return {
            ...prevState,
            xMin,
            xMax,
            final: xValue,
            isHorizontalClipher: true,
          }
        })
      } else {
        setCaliperState(null)
      }
    }
  }

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const doCaliperVertTool = (event, elements, state) => {
    if (elements.length < 1) return

    const { caliperState, setCaliperState } = state
    // No existing caliper state or caliper state finalized, so hover has no effect.
    if (
      event.type === 'mousemove' &&
      (!caliperState.current || caliperState.current.final)
    )
      return

    const eventIndex = elements[0]._index
    const datasetIndex = elements[0]._datasetIndex
    const yValue =
      elements[0]._chart.data.datasets[datasetIndex].data[eventIndex].y
    const yScaleID = elements[0]._yScale.id
    const xScaleID = elements[0]._xScale.id
    const xMin = elements[0]._xScale.min
    const xMax = elements[0]._xScale.max

    if (event.type === 'mousemove') {
      setCaliperState((prevState) => {
        const anchor = prevState.initial
        const yMin = anchor < yValue ? anchor : yValue
        const yMax = anchor > yValue ? anchor : yValue
        return { ...prevState, yMin, yMax }
      })
    }
    if (event.type === 'click') {
      // Place first caliper anchor
      if (!caliperState.current) {
        setCaliperState(
          makeCaliperVertAnnotation(yValue, xMin, xMax, xScaleID, yScaleID)
        )
      } else if (caliperState.current.initial && !caliperState.current.final) {
        setCaliperState((prevState) => {
          const anchor = prevState.initial
          const yMin = anchor < yValue ? anchor : yValue
          const yMax = anchor > yValue ? anchor : yValue
          return { ...prevState, yMin, yMax, final: yValue }
        })
      } else {
        setCaliperState(null)
      }
    }
  }

  // Placeholder data that will be switched to an API fetch
  // const data = TestRecord[0];

  const [heartRate, setHeartRate] = useState(null)

  useEffect(() => {
    const promises = []
    if (data.length > 0 && data[0].length > 0 && params.id) {

      setMinY(data.length > 0 ? Math.min(...data[0].map(({ y }) => y)) - 1 : -1)
      setMaxY(data.length > 0 ? Math.max(...data[0].map(({ y }) => y)) + 5 : 5)

      const newDataSplitted = []
      // const splitTime = 10 // seconds for one graph
      
      var splitFreq = Math.round(data[0].length / 3)
      setSplitFrequency(splitFreq)
      
      var samplingFrequency = (ecg.sampling_frequency > 0 ? ecg.sampling_frequency : 512) // Fix error default old ECG has 0 sampling frequency on Apple 
      const splitTime = splitFreq / samplingFrequency // seconds for one graph
      
      const restData = Array.from(data[0])
      for (let i = 0; i < 3; i++) {
        const elements = i !== 2 ? restData.splice(0, splitFreq) : restData

        /* Minh, I am not sure what this code helps to solve any problems, but it makes errors on the data 10k points with 20 seconds record and Sampling Frequency 500Hz, so I comment it out.
        if (i !== 0) {
          const unshifted = []
          const prevArray = newDataSplitted[i - 1]

          let currentIndex = prevArray.length - 1
          while (
            !unshifted.find(
              (element) => element.x - parseInt(element.x) === 0
            ) &&
            currentIndex > 0
          ) {
            unshifted.unshift(prevArray[currentIndex])
            currentIndex--
          }

          // this will add last integer from previous graph
          elements.unshift(...unshifted)
        }
        */

        // if there are no coordinate with x >= n*10, then this will add an empty coordinate, x value only
        if (elements[elements.length - 1].x < (i + 1) * splitTime) {
          elements.push({ x: (i + 1) * splitTime })
        }
        
        newDataSplitted.push(elements)
      }
      
      // uncomment this if you want to split automatically
      // const numbersOfRecords = []
      // const divisions = 3
      // const divided = data[0].length / divisions
      // for (let i = 0; i < divisions; i++) {
      //   if (i === divisions - 1) {
      //     numbersOfRecords.push(
      //       Math.ceil(divided - parseInt(divided) > 0 ? divided + 1 : divided)
      //     )
      //   } else {
      //     numbersOfRecords.push(parseInt(divided))
      //   }
      // }
      // let current = 0
      // numbersOfRecords.forEach((number) => {
      //   const dataSliced = data[0].slice(current, current + number)
      //   newDataSplitted.push(dataSliced)
      //   current += number
      // })

      setDataSplitted(newDataSplitted)
    }
    if (params.id !== id) {
      promises.push(setId(params.id))
      // promises.push(getData(params.id))
    }

    setIsBackdropOpen(true)
    Promise.all(promises).finally(() => {
      setIsBackdropOpen(false)
    })
  }, [data, params.id])

  useEffect(() => {

    if (params.id && params.id !== "0") {
      console.log('get ecg', params.id)
      getEcg(params.id).then((res) => (currentEcgDataRef.current = res))

    } else if (params.id === "0") {

      getData(0)
    }
  }, [params.id])
  useEffect(() => {
    // getAnalysisAnnotation
    const { warm_message } = ecg
    if (warm_message && !isLabelling) {
      setAnalysisText(warm_message)
      warmMessageRef.current = warm_message
    }

    if (isAudit) {
      setConversionValid(ecg.conversion_valid)
    }

    // getRecordLabel data.labels
    const labels = ecg.rhythms?.length
      ? ecg.rhythms.filter((rhythm) => rhythm.id > 0).map((rhythm) => rhythm.name)
      : []
    setActiveRecordLabel(labels)

    const isError = !labels.length
    validateSanityError(isError, sanityErrorsEnum.NO_RHYTHM_SELECTED)
    // getAnnotations
    setAnnotations(ecg.annotations)
    setPVCCount(ecg.pvc_count)
    setPACCount(ecg.pac_count)
    // getNotes
    if (ecg.note) {
      setNotes(ecg.note)
    }
    // set rhythms
    rhythmsRef.current = ecg.rhythms
    // getHeartRate
    setHeartRate(ecg.avg_heart_rate)
    // getIntervalPQRST
    const measurements = ecg.interval_measurements
    if (measurements) {
      const QT = measurements.find(
        (measurement) => measurement.interval_type === 'qt'
      )
      const QTC = measurements.find(
        (measurement) => measurement.interval_type === 'qtc'
      )

      if (QT) {
        setQT(QT.measurement)
      }
      if (QTC) {
        setQTC(QTC.measurement)
        const RR = 60 / ecg.avg_heart_rate
        computeQT(QTC.measurement, RR)
      }

      setIntervalLabels(measurements)
    }
  }, [ecg])

// Replace this useEffect
useEffect(() => {
  if (ecg.annotations?.length && data.length) {
    // Create a set to track the indices we've already processed
    const processedIndices = new Set();
    
    let dataset = {}
    let PVCCount = 0;
    let PACCount = 0;

    // Sort annotations by index to ensure consistent processing
    const sortedAnnotations = [...ecg.annotations]
      .filter(an => an.label)
      .sort((a, b) => a.idx - b.idx);

    sortedAnnotations.forEach((annotation) => {
      // Skip if we've already processed this index
      if (processedIndices.has(annotation.idx)) {
        console.log("Skipping duplicate annotation at index:", annotation.idx);
        return;
      }
      
      processedIndices.add(annotation.idx);
      
      // Skip processing if the index is out of bounds
      if (annotation.idx >= data[0].length) {
        console.warn("Annotation index out of bounds:", annotation.idx, "data length:", data[0].length);
        return;
      }
      
      let lDisplay = '';
      switch (annotation.label) {
        case 1:
          lDisplay = 'V';
          PVCCount++;
          break;
        case 2:
          lDisplay = 'A';
          PACCount++;
          break;
        default:
          lDisplay = '';
      }

        dataset[data[0][annotation.idx].x] = makeAnnotation(
          data[0][annotation.idx].x,
          'x-axis-0',
          lDisplay
        )

        setLabels(dataset)
      })
      if (!ecg.medium) {
        setPVCCount(PVCCount)
        setPACCount(PACCount)
      }
    } else if (ecg?.annotations === null) {
      setLabels({})
      setAnnotations([])
    }

  }, [data, ecg])
  useEffect(() => {
    if (isChartMounted) {
      const timeoutId = setTimeout(() => {
        chartMountedTimeoutRef.current = null
        const isVisible = validateLastChartScroll()
        if (!isVisible) {
          window.addEventListener('scroll', validateLastChartScroll)
          removeScrollEventRef.current = true
        }
      }, 100)
      chartMountedTimeoutRef.current = timeoutId
    }

    return () => {
      window.removeEventListener('scroll', validateLastChartScroll)
    }
  }, [isChartMounted])

  const getClosestLabel = (labels, x) => {
    let closest = null
    for (const labelX in labels) {
      const diff = Math.abs(x - labelX)
      if (!closest || Math.abs(diff) < Math.abs(closest - x)) {
        closest = labelX
      }
    }
    return closest
  }

  const handleCustomEvent =
    (type) =>
      async (e, options = {}) => {
        console.log("Beat removed by double clicked - disabled by task #QALY-742")
        // if (type === 'contextMenu' || type === 'doubleClick') {
        if (type === 'contextMenu') {
          const rect = e.target.getBoundingClientRect()
          const offsetLeft = rect.x
          const realX = e.clientX - offsetLeft - options.layoutPadding.left - 10 // 10px is left offset

          const minX = Math.min(...options.data[0].map(({ x }) => x))
          const maxX = Math.max(...options.data[0].map(({ x }) => x))

          const contextXPercent =
            realX /
            (rect.width -
              options.layoutPadding.left -
              options.layoutPadding.right -
              10)

          let contextX = contextXPercent * (maxX - minX) + minX
          if (contextX < minX) {
            contextX = minX
          }
          if (contextX > maxX) {
            contextX = maxX
          }

          let closest = null
          setLabels((prevState) => {
            closest = getClosestLabel(prevState, contextX)
            const newLabels = { ...prevState }
            delete newLabels[closest]
            return newLabels
          })

          const index = data[0].findIndex(({ x }) => String(x) === closest)
          setAnnotations((prevState) => {
            return prevState.map(
              (annotation) => {
                if (annotation.idx === index) {
                  return { ...annotation, label: 0 }
                }
                return annotation
              }

            )
          })
          
          sendEvent(id, eventTypes.beat_removed)
          
        }
      }

  const handleContextMenu = (...args) => {
    handleCustomEvent('contextMenu')(...args)
  }

  const handleDoubleClick = (...args) => {
    handleCustomEvent('doubleClick')(...args)
  }

  const handleKeyDown = (e) => {
    
    var path = e.composedPath ? e.composedPath() : ecg.path;

    const inputInPath = path.find((el) => el instanceof HTMLInputElement)
    const isArrowLeft = e.code === 'ArrowLeft'
    const isArrowRight = e.code === 'ArrowRight'

    const isChangeable =
      caliperState?.current?.isHorizontalClipher &&
      typeof caliperState.current.final === 'number' &&
      typeof caliperState.current.initial === 'number' &&
      !inputInPath &&
      (isArrowLeft || isArrowRight)

    if (isChangeable) {
      const newCaliperState = { ...caliperState.current }
      const { final, initial } = caliperState.current

      const maxX = Math.max(...dataAsyncRef.current[0].map(({ x }) => x))
      const minX = Math.min(...dataAsyncRef.current[0].map(({ x }) => x))
      const diff = Math.abs(final - initial)

      if (isArrowRight) {
        newCaliperState.initial = final
        newCaliperState.xMin = newCaliperState.initial
        newCaliperState.final = final + diff
        newCaliperState.xMax = newCaliperState.final

        const outOfRange = newCaliperState.final > maxX
        if (outOfRange) {
          newCaliperState.final = maxX
          newCaliperState.xMax = newCaliperState.final
          newCaliperState.initial = maxX - diff
          newCaliperState.xMin = newCaliperState.initial
        }
      }
      if (isArrowLeft) {
        newCaliperState.initial = initial - diff
        newCaliperState.xMin = newCaliperState.initial
        newCaliperState.final = initial
        newCaliperState.xMax = newCaliperState.final

        const outOfRange = newCaliperState.initial < minX
        if (outOfRange) {
          newCaliperState.final = minX + diff
          newCaliperState.xMax = newCaliperState.final
          newCaliperState.initial = minX
          newCaliperState.xMin = newCaliperState.initial
        }
      }

      setCaliperState(newCaliperState)
      setChartTooltip(null)
    }
  }

  const onLineChartMount = () => {
    if (!isChartMounted) {
      setIsChartMounted(true)
    }
  }

  const onRhythmsChange = (labels) => {
    rhythmsRef.current = labels
    const isError = !labels.length
    validateSanityError(isError, sanityErrorsEnum.NO_RHYTHM_SELECTED)
  }

  const getFieldValue = (field) => {
    return (intervalLabels.find(
      ({ interval_type }) => interval_type === field
    ) || {}
    ).measurement
  }

  const getFieldHelper = (field, value) => {
    if ((value || getFieldValue(field)) >= INTERVAL_MAX_VALUE[field]) {
      return <div style={{ color: "#ED6C02", marginLeft: "10px", fontSize: "12px" }}><svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 9.5H11.5L6 0L0.5 9.5ZM6.5 8H5.5V7H6.5V8ZM6.5 6H5.5V4H6.5V6Z" fill="#ED6C02" />
      </svg>
        Wide</div>
    } else if ((value || getFieldValue(field)) <= INTERVAL_MIN_VALUE[field]) {
      return <div style={{ color: "#ED6C02", marginLeft: "10px", fontSize: "12px" }}><svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 9.5H11.5L6 0L0.5 9.5ZM6.5 8H5.5V7H6.5V8ZM6.5 6H5.5V4H6.5V6Z" fill="#ED6C02" />
      </svg>
        Short</div>
    }
  }

  const getFieldColor = (field) => {
    return (getFieldValue(field) < INTERVAL_MAX_VALUE[field] && getFieldValue(field) > INTERVAL_MIN_VALUE[field]) ? "gray" : "warning"
  }
  const tools = [
    {
      name: 'caliper',
      icon: HeightIcon,
      title: 'Caliper Horizontal',
      clickHandler: defaultToolHandler,
    },
    {
      name: 'caliperVert',
      icon: HeightIcon,
      title: 'Caliper Vertical',
      clickHandler: defaultToolHandler,
    },
    {
      name: 'vLabelTool',
      icon: LabelImportantIcon,
      title: 'PVC Label',

      id: "task-pac-pvc",
      clickHandler: doLabelTool,
      wrapper: badgeFactory(availableLabels[labelMapping.VLABEL].badgeDisplay),
      labelMapping: labelMapping.VLABEL,
    },
    {
      name: 'aLabelTool',
      icon: LabelImportantIcon,
      title: 'PAC Label',
      id: "task-pac-pvc",
      clickHandler: doLabelTool,
      wrapper: badgeFactory(availableLabels[labelMapping.ALABEL].badgeDisplay),
      labelMapping: labelMapping.ALABEL,
    },
  ]


  const toolState = {
    activeTool,
    setActiveTool,
    currentTime,
    setCurrentTime,
    labels,
    setLabels,
    caliperState,
    setCaliperState,
    activeLabel,
    setActiveLabel,
    activeRecordLabel,
    setActiveRecordLabel,
    minY,
    maxY,
    setMaxY,
    setMinY,
    id: id || '',
    data,
    setData,
    setIsInverted,
    isInverted,
  }

  useEffect(() => {
    const PVC = Object.values(labels).filter((x) => x.label.content === 'V');
    const PAC = Object.values(labels).filter((x) => x.label.content === 'A');
    setPACCount(PAC.length);
    setPVCCount(PVC.length);
  }, [labels])

  useEffect(() => {
    if (notesSaving && notes.length > 0 && id) {
      API.post(endpoints.SETNOTES + id, {
        note: notes,
      })
        .then(() => {
          setNotesSaving(false)
        })
        .catch((e) => {
          if (e.response?.status === 401) {
            API.get(endpoints.LOGOUT).finally((e) => {
              removeCookie(SESSION_COOKIE)
              dispatch(setNotAuthenticated())
            })
          }
        })
    }
  }, [notes, notesSaving])

  const intervalTypes = ['p', 'qrs', 'pr']

  const handleFixErrorsClick = () => {
    setIsLoaderDialogOpen(false)
    window.scrollTo(0, 0)
  }

  const handleCloseSnackbar = () => {
    setSnackbar(null)
  }

  useEffect(() => {
    const isError = !(
      intervalTypes.some(
        (type) =>
          intervalLabels.find((label) => label.interval_type === type)
            ?.measurement
      ) || QT
    )

    if (!ecg?.medium) {
      validateSanityError(isError, sanityErrorsEnum.EMPTY_PQRST)

    }
  }, [intervalLabels, QT, heartRate, intervalTypes, ecg?.medium])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      chartMountedTimeoutRef.current &&
        clearTimeout(chartMountedTimeoutRef.current)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  let errorWarningText = 'You have '
  if (reviewErrors.length) {
    errorWarningText += `${reviewErrors.length} error${reviewErrors.length > 1 ? 's' : ''
      }`
    if (reviewWarnings.length) {
      errorWarningText += ` and`
    }
  }
  if (reviewWarnings.length) {
    errorWarningText += ` ${reviewWarnings.length} warning${reviewWarnings.length > 1 ? 's' : ''
      }`
  }

  const [isOpen, setIsOpen] = useState(false)

  const [score, setScore] = useState(0)
  const getAnswers = () => {
    API.post(endpoints.PRACTICE_COMPLETE(id)).then((res) => {
      setAnswer(res.data.data.result)
      setScore(res.data.data.result.filter(id => rhythmsRef?.current.find(task => task.id === id)).length / Math.max(rhythmsRef?.current.length, res.data.data.result.length) * 100)
    })
  }

// Helper function to calculate QT from QTc
function calculateQT(qtc, heartRate) {
  if (!heartRate || heartRate === 0) return "";
  
  const rr = 60 / heartRate;
  console.log("heartRate", heartRate)
  const qt = qtc * Math.sqrt(rr);
  console.log("qtc", qtc)
  return Math.round(qt);
}


  // Add new cleanup function
  const clearEctopicBeats = () => {
    setbobbyEctopics({});
    setBobbyData(null);
    setBobbyWarmMessage(null);
  };
    

  // Getting Bobby response
  const [isLoadingBobby, setIsLoadingBobby] = useState(false);
  const [bobbyData, setBobbyData] = useState(null);
  const [bobbyWarmMessage, setBobbyWarmMessage] = useState(null);
  const [bobbyEctopics, setbobbyEctopics] = useState({});

  const handlePQRSTFetch = async () => {
    setIsLoadingBobby(true);
    clearEctopicBeats();
    
    try {
        // Fetch PQRST values
        const PQRSTValues = await API.bobby.getPQRST(id);
      
        // Log PQRST values
        console.log("PQRST Values:", PQRSTValues);
        
        // Handle PQRST values
        if (PQRSTValues?.data?.data?.interval_measurements) {
          const measurements = PQRSTValues.data.data.interval_measurements;
          
          setIntervalLabels(measurements);

          // Set QTC if present
          const qtcMeasurement = measurements.find(m => m.interval_type === 'qtc');
          if (qtcMeasurement) {
            setQTC(qtcMeasurement.measurement);
            setQT(calculateQT(qtcMeasurement.measurement, heartRate));
          }
       }
       clearEctopicBeats();
    } catch (error) {
      console.error('Error in handlePQRSTFetch:', error);
    } finally {
      setIsLoadingBobby(false);
    }
};

  const handleBobbyFetch = async () => {
    setIsLoadingBobby(true);
    clearEctopicBeats();
    
      try {
        // Fetch both ectopic beats and PQRST values in parallel
        const [ectopiBeats, PQRSTValues] = await Promise.all([
          API.bobby.getEctopiBeats(id),
          API.bobby.getPQRST(id)
        ]);
      
        // Log PQRST values
        console.log("PQRST Values:", PQRSTValues);
        
        // Handle PQRST values
        if (PQRSTValues?.data?.data?.interval_measurements) {
          const measurements = PQRSTValues.data.data.interval_measurements;
          
          // Set interval labels using the measurements
          setIntervalLabels(measurements);

          // Set QTC if present
          const qtcMeasurement = measurements.find(m => m.interval_type === 'qtc');
          if (qtcMeasurement) {
            setQTC(qtcMeasurement.measurement);
            setQT(calculateQT(qtcMeasurement.measurement, heartRate));
          }
       }
      
      // Use 514 as default sampling frequency if not provided
      const samplingFreq = ecg.sampling_frequency || 514;
      
      console.log("Bobby response", ectopiBeats?.data);

      if (ectopiBeats?.data?.beat_labels) {      
        const beatEntries = Object.entries(ectopiBeats.data.beat_labels);
        
        beatEntries.forEach((([timepoint, beatValue], index) => {
          // Get previous timepoint if it exists, otherwise use current - Removed after Vedant update to Quill
          // const previousTimepoint = index > 0 ? beatEntries[index - 1][0] : timepoint;
          // console.log(`Current timepoint: ${timepoint}, Using timepoint: ${previousTimepoint}`);
          
          const adjustedTimepoint = parseInt(timepoint);
          const timeInSeconds = adjustedTimepoint / samplingFreq;
                
          // Find matching data point
          const dataPoint = data[0].find(point => 
            Math.abs(point.x - timeInSeconds) < 0.01
          );
      
          if (dataPoint) {
            const xValue = dataPoint.x;
            const dataIndex = data[0].indexOf(dataPoint);
      
            // Skip if label already exists
            if (xValue in labels) {
              console.log("Label already exists at:", xValue);
              return;
            }
      
            console.log(`Adding label at x=${xValue}, index=${dataIndex}`);
      
            const label = parseInt(beatValue);
            let labelDisplay;
            let borderColor;
            switch(label) {
              case 0:
                labelDisplay = ''; // Remove 'N' label
                break;
              case 1:
                labelDisplay = 'V';
                break;
              case 2:
                labelDisplay = 'A';
                break;
              default:
                labelDisplay = '';
            }
    
            if (label === 1 || label === 2) {
              setAnnotations(prev => {
                const prevState = prev || [];
                return [
                  ...prevState,
                  {
                    idx: dataIndex,
                    label,
                  }
                ];
              });
            }
      
            setLabels(prev => ({
              ...prev,
              [xValue]: makeAnnotation(
                xValue,
                'x-axis-0',
                labelDisplay,
                labelDisplay === '' ? { borderColor: '#666', showLabel: false } : { borderColor: 'blue', showLabel: true }
              )
            }));
            
    
            if (ectopiBeats?.data?.beat_labels) {      
              const beatEntries = Object.entries(ectopiBeats.data.beat_labels);
              
              let minTimeDiffMs = Infinity;
              let maxTimeDiffMs = -Infinity;
              let minTimepoint = null;
              let maxTimepoint = null;
              
              beatEntries.forEach(([timepoint, _], index) => {
                if (index < beatEntries.length - 1) {
                  const currentTimepoint = parseFloat(timepoint);
                  const nextTimepoint = parseFloat(beatEntries[index + 1][0]);
                  
                  const timeDiffSec = (nextTimepoint - currentTimepoint) / samplingFreq;
                  const timeDiffMs = Math.round(timeDiffSec * 1000);
                  
                  if (timeDiffMs < minTimeDiffMs) {
                    minTimeDiffMs = timeDiffMs;
                    minTimepoint = currentTimepoint;
                  }
                  
                  if (timeDiffMs > maxTimeDiffMs) {
                    maxTimeDiffMs = timeDiffMs;
                    maxTimepoint = currentTimepoint;
                  }
                }
              });
              
              const newLabels = beatEntries.reduce((acc, [timepoint, _], index) => {
                if (index < beatEntries.length - 1) {
                  const currentTimepoint = parseFloat(timepoint);
                  const nextTimepoint = parseFloat(beatEntries[index + 1][0]);
                  
                  const timeDiffSec = (nextTimepoint - currentTimepoint) / samplingFreq;
                  const timeDiffMs = Math.round(timeDiffSec * 1000);
                  
                  const currentTime = currentTimepoint / samplingFreq;
                  const nextTime = nextTimepoint / samplingFreq;
                  const middleTime = (currentTime + nextTime) / 2;
                  
                  let labelContent = `${timeDiffMs}`;
                  
                  if (currentTimepoint === minTimepoint) {
                    labelContent = `⬇ ${timeDiffMs}`; 
                  } else if (currentTimepoint === maxTimepoint) {
                    labelContent = `⬆ ${timeDiffMs}`;  
                  }
            
                  acc[`distance-${currentTimepoint}`] = makeRRInterval(
                    middleTime,
                    'x-axis-0',
                    labelContent,
                  );
                }
                return acc;
              }, {});
                          
              setLabels(prev => ({
                ...prev,
                ...newLabels
              }));
            }            
          }
        }));
    
        clearEctopicBeats();
    }    
      
  
      if (ectopiBeats?.data?.rhythm_labels?.length > 0) {
        setBobbyData(ectopiBeats.data.rhythm_labels);
      }

      if (ectopiBeats?.data?.warm_message) {
        setBobbyWarmMessage(ectopiBeats.data.warm_message);
      }
  
      const isBobbyValid = ectopiBeats?.data?.escalate_review_to_humans;

      if (isBobbyValid) {
              Toastr.error('This is review maybe not be accurate');
      }
  
    } catch (error) {
      console.error('Error in handleBobbyFetch:', error);
    } finally {
      setIsLoadingBobby(false);
    }
  };
  
  
  // tutorial

  const { setIsOpen: setIsTutorialOpen, setCurrentStep, setDisabledActions } = useTour()
  useEffect(() => {
    if (mode === "tutorial" && data.length > 0) {
      setIsTutorialOpen(true)
      setDisabledActions(false)
      setCurrentStep(4)
    }
    return () => {
      setIsTutorialOpen(false)
      setCurrentStep(0)
    }
  }, [mode, data[0]])

  const topChartHeight = 0.8 * 250;
  const yMax = Math.max(topChartHeight, 0);


  const getBPM = (d) => d.y;
  const yScale = useMemo(
    () =>
      data.length > 0 && scaleLinear({
        range: [yMax, 0],
        domain: [min(data[0], getBPM), max(data[0], getBPM) || 0],
        nice: true,
      }),
    [yMax, data],
  );

  // Add clear functions
  const clearPVC = () => {
    // Clear visual labels
    const updatedLabels = { ...labels };
    Object.keys(updatedLabels).forEach(key => {
      if (updatedLabels[key]?.label?.content === 'V') {
        delete updatedLabels[key];
      }
    });
    setLabels(updatedLabels);
    setPVCCount(0);
  
    // Clear annotations - filter out PVC beats (label 1)
    const updatedAnnotations = annotations.filter(
      annotation => annotation.label !== 1
    );
    setAnnotations(updatedAnnotations);
  
    // Clear Bobby data
    if (bobbyData) {
      const updatedBobbyData = { ...bobbyData };
      if (updatedBobbyData.beat_labels) {
        Object.keys(updatedBobbyData.beat_labels).forEach(key => {
          if (updatedBobbyData.beat_labels[key] === 'V') {
            delete updatedBobbyData.beat_labels[key];
          }
        });
      }
      setBobbyData(updatedBobbyData);
    }
  
    // Clear ectopic beats
    const updatedEctopics = { ...bobbyEctopics };
    Object.keys(updatedEctopics).forEach(key => {
      if (updatedEctopics[key] === 'V') {
        delete updatedEctopics[key];
      }
    });
    setbobbyEctopics(updatedEctopics);
  };
  
  const clearPAC = () => {
    // Clear visual labels
    const updatedLabels = { ...labels };
    Object.keys(updatedLabels).forEach(key => {
      if (updatedLabels[key]?.label?.content === 'A') {
        delete updatedLabels[key];
      }
    });
    setLabels(updatedLabels);
    setPACCount(0);
  
    // Clear annotations - filter out PAC beats (label 2)
    const updatedAnnotations = annotations.filter(
      annotation => annotation.label !== 2
    );
    setAnnotations(updatedAnnotations);
  
    // Clear Bobby data
    if (bobbyData) {
      const updatedBobbyData = { ...bobbyData };
      if (updatedBobbyData.beat_labels) {
        Object.keys(updatedBobbyData.beat_labels).forEach(key => {
          if (updatedBobbyData.beat_labels[key] === 'A') {
            delete updatedBobbyData.beat_labels[key];
          }
        });
      }
      setBobbyData(updatedBobbyData);
    }
  
    // Clear ectopic beats
    const updatedEctopics = { ...bobbyEctopics };
    Object.keys(updatedEctopics).forEach(key => {
      if (updatedEctopics[key] === 'A') {
        delete updatedEctopics[key];
      }
    });
    setbobbyEctopics(updatedEctopics);
  };

  return <>
    <Backdrop open={isBackdropOpen} />
    <CssBaseline />
    <AnnotationToolbar
      {...toolState}
      chart={CHART}
      tools={tools}
      PVCCount={PVCCount}
      PACCount={PACCount}
      setPACCount={setPACCount}
      setPVCCount={setPVCCount}
      clearPVC={clearPVC}
      clearPAC={clearPAC}
      invertECG={invertECG}
      isFile={ecgViewType === 'media_file'}
    />
    <CollapseHorizontal
      title="Audit tool"
      className={classes.sectionsWrapper}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      maxWidth={500}
      isAudit={isAudit}
      solid={
        <Grid container direction="row" style={{ background: "#f7f8fa" }}>
          <Grid xs={(isOpen || answer) ? 8 : 12} direction="column" style={{ gap: "20px", padding: "10px" }} >
            {(!!reviewErrors.length || !!reviewWarnings.length) && (
              <Grid item xs={12}>
                <ColCard collapseNoDivider title={errorWarningText}>
                  {reviewErrors.map((error) => (
                    <Grid container wrap="nowrap" alignItems="center">
                      <Grid item>
                        <Typography
                          color="secondary"
                          style={{ lineHeight: 1 }}
                        >
                          <HighlightOffIcon />
                          &nbsp;
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography color="secondary">
                          Error:&nbsp;
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>{error.text}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                  {reviewWarnings.map((warning) => (
                    <Grid container wrap="nowrap" alignItems="center">
                      <Grid item style={{ color: '#EF6C00' }}>
                        <Typography color="inherit" style={{ lineHeight: 1 }}>
                          <HighlightOffIcon />
                          &nbsp;
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography color="inherit">
                          Warning:&nbsp;
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>{warning.text}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </ColCard>
              </Grid>
            )}
            <Grid style={{ marginBottom: "30px" }} container direction={(isAudit || isPractice) ? 'column' : 'row'} xs={12} wrap="nowrap"   >
              <Grid item xs={isAudit ? 12 : 6} >
                <ColCard collapseNoDivider title={'Note - Sent by: ' + ecg.user_info + (ecg.is_deleted ? '   This ECG has been deleted by user.' : '')} >
                  <Typography id="task-note">
                    {notes
                      ? notes
                      : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75px" }}><img src={Empty} alt="media file" style={{ marginRight: "10px" }} /> No note to display</div>}
                  </Typography>
                </ColCard>
              </Grid>
              <Grid item xs={isAudit ? 12 : 6} id="task-pqrst">
                <ColCard collapseNoDivider title="PQRST">
                  <Grid container alignItems="center">
                    {/* <Typography>HR: {heartRate} bpm</Typography> */}
                    <Grid item className={classes.intervalItem}>
                      <TextField
                        label="HR"
                        variant="outlined"
                        size="small"
                        aria-describedby="outlined-weight-helper-text"
                        sx={{
                          border: "1px"
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>bpm</InputAdornment>
                          ),
                        }}
                        value={heartRate || ''}
                        onChange={({ target: { value } }) => {
                          if (
                            parseInt(value) > 1000 ||
                            !/^[-+]?[0-9]+$|^$/g.test(value)
                          ) {
                            return
                          }
                          setHeartRate(Number(value))
                          if (value > 0) {

                            const RR = 60 / value
                            if (typeof RR === 'number') {
                              computeQTC(QT, RR)
                            }
                          }
                        }
                        }
                      />
                    </Grid>

                    {intervalTypes.map((il) => {
                      return (
                        <Grid item className={classes.intervalItem}>
                          <TextField
                            color={getFieldColor(il)}
                            focused={getFieldValue(il)}
                            label={il.toUpperCase()}
                            placeholder={il.toUpperCase()}
                            variant="outlined"
                            size="small"
                            aria-describedby="outlined-weight-helper-text"
                            sx={{
                              border: "1px"
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment>ms</InputAdornment>
                              ),
                            }}
                            value={
                              getFieldValue(il) || ''
                            }
                            onChange={({ target: { value } }) => {
                              if (
                                parseInt(value) > 1000 ||
                                !/^[-+]?[0-9]+$|^$/g.test(value)
                              ) {
                                return
                              }
                              setIntervalLabels([
                                {
                                  interval_type: il,
                                  measurement: value,
                                },
                                ...intervalLabels.filter(
                                  ({ interval_type }) => interval_type !== il
                                ),
                              ])
                            }}
                          />
                          <FormHelperText id="outlined-weight-helper-text" style={{ height: "0px" }}>{getFieldHelper(il)}</FormHelperText>

                        </Grid>
                      );
                    })}
                    <Grid item className={classes.intervalItem}>
                      <TextField
                        label={'QT'}
                        placeholder={'QT'}
                        variant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: <InputAdornment>ms</InputAdornment>,
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        value={QT}
                        onChange={({ target: { value } }) => {
                          if (parseInt(value) > 1000 || !/^[-+]?[0-9]+$|^$/g.test(value)) {
                            return
                          }

                          setQT(value)

                          if (value === '') {
                            if (QTC !== 0) {
                              setQTC(0)
                            }
                            return
                          }

                          const RR = 60 / heartRate
                          if (typeof RR === 'number') {
                            computeQTC(Number(value), RR)
                          }
                        }}
                      />
                      <FormHelperText id="outlined-weight-helper-text"></FormHelperText>
                    </Grid>
                    <Grid item className={classes.intervalItem}>
                      <Typography>QTC: {QTC} ms</Typography>
                      <FormHelperText id="outlined-weight-helper-text" style={{ height: "0px" }}>{getFieldHelper('qtc', QTC)}</FormHelperText>

                    </Grid>
                  </Grid>
                </ColCard>
              </Grid>
            </Grid>

            <Grid item xs={ecgViewType === 'media_file' ? 6 : 12} className={classes.ecgWrapper}>
              {ecgViewType === 'media_file' &&
                <div>
                  <ColCard
                    title="ECG File"
                    collapseNoDivider
                    renderHeader={{
                      withoutTitle: false,
                      render: () => (
                        <Grid
                          item
                          container
                          alignItems="center"
                          justifyContent="flex-end"
                          style={{ width: 'auto' }}
                        >
                          <Grid item style={{ marginRight: 10, marginTop: 'auto' }}>
                            <a href={`${ecg?.medium}`} target="_blank" rel="noreferrer" title="Download file">
                              <DownloadIcon/>
                            </a>
                          </Grid>
                        {isPdf(ecg) &&
                          <Grid item>
                            <Button onClick={(e) => setEcgViewType('graph')}>
                              <TimelineIcon/>
                            </Button>
                          </Grid>
                        }
                        </Grid>
                      )}}
                >
                  {
                    isPdf(ecg) ?
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.js">
                        <div style={{
                            height: '650px',
                            width: '700px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                          }}>
                        <Viewer fileUrl={`${ecg?.medium}`} plugins={[defaultLayoutPluginInstance]} /></div>
                      </Worker>
                      :
                      <TransformWrapper initialScale={1}>
                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                          <div
                            style={{ display: "flex", alignItems: "center", gap: "10px", width: "1000px !important", flexDirection: "column" }}>
                            <div className="tools">
                              <Button onClick={() => zoomIn()}><ZoomIn /></Button>
                              <Button onClick={() => zoomOut()}><ZoomOut /></Button>
                              <Button onClick={() => resetTransform()}><ZoomOutMap /></Button>
                              <Button onClick={() => setIsInverted(prev => !prev)}><FlipCameraAndroid /></Button>
                            </div>
                            <TransformComponent >
                              <img src={`${ecg?.medium}`} alt="ECG" style={{ maxHeight: "900px", transform: isInverted ? `scaleY(-1)` : "scaleX(1)" }} />
                            </TransformComponent>
                          </div>
                        )}
                      </TransformWrapper>
                  }

                </ColCard>

              </div>}

        {ecgViewType === 'graph' &&
            <ColCard
              title={'ECG Strip : ' + TASK_TYPE[ecg.source]}
              collapseNoDivider
              renderHeader={{
                withoutTitle: false,
                render: () => (
                  <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    style={{ width: 'auto' }}
                  >
                    {isPdf(ecg) &&
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                            onChange={({ target: { checked } }) => {
                              setConversionValid(!checked);
                            }}
                            />
                          }
                          label="Don’t show user ECG Conversion."
                        />
                      </Grid>
                    }
                    <Grid item>
                      <FormLabel style={{ marginRight: 10 }}>
                        Box type:
                      </FormLabel>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        row
                        aria-label="boxType"
                        name="boxType"
                        value={boxType}
                        onChange={(e) => handleChange(e, 'boxType')}
                      >
                        {getKeysArray(boxTypes).map((type) => (
                          <FormControlLabel
                            value={type}
                            control={<Radio color="primary" />}
                            label={`${capitalizeFirstLetter(type)} box`}
                            labelPlacement="end"
                            size="small"
                          />
                        ))}
                      </RadioGroup>
                    </Grid>

                  {isPdf(ecg) &&
                    <Grid item>
                      <Button style={{ marginRight: 10 }} onClick={(e) => setEcgViewType('media_file')}>
                        <PictureAsPdfIcon/>
                      </Button>
                    </Grid>
                  }
                  </Grid>
                ),
              }}
            >
              {dataSplitted.map((data, i) => {
                const startedIndex = i * splitFrequency // max index when 10 sec intervals
                return (
                  <Box className={classes.chartWrapper}>
                       <LineChart
                        onDoubleClick={handleDoubleClick}
                        onContextMenu={handleContextMenu}
                        key={i}
                        data={[data]}
                        labels={labels}
                        caliperState={caliperState}
                        tools={tools}
                        activeTool={activeTool}
                        doCaliperTool={doCaliperTool}
                        doCaliperVertTool={doCaliperVertTool}
                        setCurrentTime={setCurrentTime}
                        toolMapping={toolMapping}
                        toolState={toolState}
                        isBig={boxType === boxTypes.LARGE}
                        maxY={maxY}
                        minY={minY}
                        startedIndex={startedIndex}
                        onMount={onLineChartMount}
                      />
                  </Box>
                )
              })}
              <Box ref={lastChartRef} />
            </ColCard>
        }
            </Grid>

          </Grid>
          {answer && <Grid xs={4} style={{ padding: "10px" }}>
            <Grid container direction='column' xs={12} wrap="nowrap" >
              <Grid item xs={isAudit ? 12 : 6}>
                <ColCard collapseNoDivider title="Answers">
                  <Typography mb={2}>
                    Interpretation: <span style={score > 81 ? { color: 'green' } : { color: "orange" }}>{score.toFixed(2)}%</span>
                  </Typography>

                  <Typography mb={2} color="gray"
                    style={{ lineHeight: 1 }}>
                    YOUR INTERPRETATION
                  </Typography>
                  {rhythmsRef?.current?.map(option =>
                    <Typography gap={1} style={{ display: "flex", alignItems: "center" }}>
                      <FiberManualRecordIcon
                        style={{ color: getLabelColorByCategory(option.category) }}
                      />{option.name}
                    </Typography>
                  )}

                  <Typography mb={2} mt={2} color="gray"
                    style={{ lineHeight: 1 }}>
                    CORRECT INTERPRETATION
                  </Typography>

                  {allRhythms.filter(option => answer.includes(option.id)).map(option =>
                    <Typography gap={1} key={option.id} style={{ display: "flex", alignItems: "center" }}>
                      <FiberManualRecordIcon
                        style={{ color: getLabelColorByCategory(option.category) }}
                      />{option.name}
                    </Typography>)}
                </ColCard>
              </Grid>
            </Grid>


          </Grid>}
        </Grid>
      }
      resizing={<AuditCreate forceSubmit={forceAuditSubmit} handleChangeSubmitState={handleChangeSubmitState} getadditionalData={getadditionalData} PVCCount={PVCCount} PACCount={PACCount} />}
    />
    <UserCvdSurvey id={params.id} mode={mode} />
    <UserHistory id={params.id} mode={mode} />
    <InterpretationBar
      handleSubmit={isPractice ? getAnswers : handleSubmit}
      analysisText={analysisText}
      warmMessageRef={warmMessageRef}
      id={id || ''}
      mode={mode}
      finished={answer}
      activeRecordLabel={activeRecordLabel}
      sanityErrors={sanityErrors}
      onRhythmsChange={onRhythmsChange}
      setSnackbarECGView={setSnackbar}
      validateSanityError={validateSanityError}
      selectRhythms={selectRhythms}
      PVCCount={PVCCount}
      PACCount={PACCount}
      notes={notes}
      answer={answer}
      name={ecg.user_info}
      heartrate={heartRate}
      isLabelling={isLabelling}
      isLoadingBobby={isLoadingBobby}
      onBobbyFetch={handleBobbyFetch}
      onPQRSTFetch={handlePQRSTFetch}
      bobbyData={bobbyData}
      bobbyWarmMessage={bobbyWarmMessage}
      intervalLabels={intervalLabels}
    />
    {caliperState.current && <AbsoluteTooltip state={chartTooltip.current} doNotRender={!caliperState}>
      Caliper:{' '}
      {!!(caliperState.current?.xMax && caliperState.current?.xMin) &&
        <>
          {parseInt(
            (caliperState.current.xMax - caliperState.current.xMin) * 1000
          )} ms <div>Heart rate: {(60000 / parseInt(
            (caliperState.current.xMax - caliperState.current.xMin) * 1000
          )).toFixed(0)} bpm</div> </>}


    </AbsoluteTooltip>}
    <LoaderDialog
      open={isLoaderDialogOpen}
      onClose={() => setIsLoaderDialogOpen(false)}
      submitState={submitState}
      setForceSubmit={setForceSubmit}
      isAudit={isAudit}
      isLabelling={isLabelling}
      onFixErrorsClick={handleFixErrorsClick}
      handleSubmitNoValidation={handleSubmitNoValidation}
    />
    <Snackbar
      open={snackbar}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
    >
      <Alert onClose={handleCloseSnackbar} severity="error">
        {snackbar &&
          snackbar
            .split('')
            .map((letter, i) => (i ? letter : letter.toUpperCase()))
            .join('')}
      </Alert>
    </Snackbar>
  </>;
}

export default EKGViewer